import React, { useState } from 'react';
import figlet from 'figlet';
import standard from 'figlet/importable-fonts/Standard.js';

figlet.parseFont('Standard', standard);

const BigTextGenerator = () => {
  const [text, setText] = useState('');
  const [bigText, setBigText] = useState('');

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const generateBigText = () => {
    figlet.text(text, {
      font: 'Standard'
    }, (err, data) => {
      if (err) {
        console.log('Something went wrong...');
        console.dir(err);
        return;
      }
      setBigText(data);
    });
  };
  const downloadText = () => {
    const blob = new Blob([bigText], { type: 'text/plain' });
    const anchor = document.createElement('a');
    anchor.download = 'big_text.txt';
    anchor.href = window.URL.createObjectURL(blob);
    anchor.target = '_blank';
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(bigText).then(() => {
      alert('Text copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  return (
    <div>
      <h1>Big Text Generator</h1>
      <textarea 
        value={text} 
        onChange={handleChange} 
        placeholder="Type or paste your content here" 
        style={{ width: '100%', height: '100px', marginBottom: '10px' }} 
      />
      <div style={{ display: 'flex', gap: '10px' }}>
        <button onClick={generateBigText}>Generate Big Text</button>
        <button onClick={downloadText}>Download Text</button>
        <button onClick={copyToClipboard}>Copy to Clipboard</button>
      </div>
      <div style={{ marginTop: '20px', fontSize: '16px', whiteSpace: 'pre-wrap', fontFamily: 'monospace' }}>
        {bigText}
      </div>
    </div>
  );
};

export default BigTextGenerator;
