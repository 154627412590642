import React, { useState } from 'react';
import languages from './languages'; // Assuming you have this file for multilingual support

const TextCaseConverter = () => {
  const [text, setText] = useState('');
  const [language, setLanguage] = useState('en');
  const [convertedText, setConvertedText] = useState('');

  const handleChange = (e) => {
    setText(e.target.value);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const convertText = (caseType) => {
    let newText = '';
    switch (caseType) {
      case 'sentence':
        newText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        break;
      case 'lower':
        newText = text.toLowerCase();
        break;
      case 'upper':
        newText = text.toUpperCase();
        break;
      case 'capitalized':
        newText = text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        break;
      case 'alternating':
        newText = text.split('').map((char, index) => index % 2 === 0 ? char.toLowerCase() : char.toUpperCase()).join('');
        break;
      case 'title':
        newText = text.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
        break;
      case 'inverse':
        newText = text.split('').map(char => char === char.toUpperCase() ? char.toLowerCase() : char.toUpperCase()).join('');
        break;
      default:
        newText = text;
    }
    setConvertedText(newText);
  };

  const downloadText = () => {
    const blob = new Blob([convertedText], { type: 'text/plain' });
    const anchor = document.createElement('a');
    anchor.download = 'converted_text.txt';
    anchor.href = window.URL.createObjectURL(blob);
    anchor.target = '_blank';
    anchor.style.display = 'none';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(convertedText).then(() => {
      alert('Text copied to clipboard');
    });
  };

  const clearText = () => {
    setText('');
    setConvertedText('');
  };

  const lang = languages[language];

  return (
    <div className="text-case-converter">
      <h1>Text Case Converter</h1>
      <div className="language-selector">
        <label htmlFor="language">Choose Language:</label>
        <select id="language" value={language} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="es">Español</option>
          <option value="de">Deutsch</option>
          <option value="zh">中文</option>
          <option value="ja">日本語</option>
          <option value="fr">Français</option>
          <option value="pt">Português</option>
          <option value="ru">Русский</option>
          <option value="it">Italiano</option>
        </select>
      </div>
      <textarea 
        value={text} 
        onChange={handleChange} 
        placeholder="Type or paste your content here" 
        className="text-input"
      />
      <div className="button-container">
        <button onClick={() => convertText('sentence')}>{lang.sentenceCase}</button>
        <button onClick={() => convertText('lower')}>{lang.lowerCase}</button>
        <button onClick={() => convertText('upper')}>{lang.upperCase}</button>
        <button onClick={() => convertText('capitalized')}>{lang.capitalizedCase}</button>
        <button onClick={() => convertText('alternating')}>{lang.alternatingCase}</button>
        <button onClick={() => convertText('title')}>{lang.titleCase}</button>
        <button onClick={() => convertText('inverse')}>{lang.inverseCase}</button>
        <button onClick={downloadText}>{lang.downloadText}</button>
        <button onClick={copyToClipboard}>{lang.copyToClipboard}</button>
        <button onClick={clearText}>{lang.clear}</button>
      </div>
      <div className="converted-text">
        {convertedText}
      </div>
     {/* Empty space */}
      <div style={{ height: '10rem' }}></div>
      <h3>Welcome to the Text Case Converter</h3>
      <p>Discover this versatile online text tool where you can switch between upper and lower case, or choose options like capitalizing, decapitalizing, creating mixed case, and more. Explore the various functionalities below:</p>

      <div className="case-container">
        <h3>Sentence Case</h3>
        <p>This converter lets you input any text and automatically formats it into a standard sentence structure. It starts by capitalizing the first letter of each sentence. The rest of the text is transformed into lowercase, including changing 'i' to 'I'. Each letter following a period is also capitalized. Note: It does not capitalize proper nouns or geographic locations.</p>
        <p>Example: This is an example of sentence case.</p>

        <h3>Lower Case</h3>
        <p>This feature converts all the text you enter into lowercase. Just copy your text into the designated box, select the 'lower case' option, and see the transformation.</p>
        <p>Example: this is an example of lower case.</p>

        <h3>Upper Case</h3>
        <p>Convert any text to all upper case letters, turning any lower case letters into CAPITALS while maintaining already capitalized letters.</p>
        <p>Example: THIS IS AN EXAMPLE OF UPPER CASE.</p>

        <h3>Capitalized Case</h3>
        <p>This tool automatically capitalizes the first letter of each word, leaving the rest in lower case.</p>
        <p>Example: This Is An Example Of Capitalized Case.</p>

        <h3>Alternating Case</h3>
        <p>Transform any text into a pattern of alternating lower and upper case letters within the same word, regardless of its original format.</p>
        <p>Example: tHiS Is aN ExAmPlE Of aLtErNaTiNg cAsE.</p>

        <h3>Title Case</h3>
        <p>Ideal for those unsure about how to capitalize titles in essays, this converter capitalizes key words within titles correctly while leaving smaller words like “an” in lower case.</p>
        <p>Example: This Is an Example of Title Case.</p>

        <h3>Reverse Text Generator</h3>
        <p>If you need to reverse the order of characters in your text, this tool makes it easy. Write your text as usual, then watch as it is reversed.</p>
        <p>Example: .txet sdrawkcab fo ecnetnes elpmaxe na si sihT</p>
      </div>
    </div>
  );
};

export default TextCaseConverter;
