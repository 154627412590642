import React, { useState } from 'react';
import './PasswordGenerator.css';

const PasswordGenerator = () => {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(10);
  const [includeLower, setIncludeLower] = useState(true);
  const [includeUpper, setIncludeUpper] = useState(true);
  const [includeNumbers, setIncludeNumbers] = useState(true);
  const [includeSymbols, setIncludeSymbols] = useState(true);
  const [excludeAmbiguous, setExcludeAmbiguous] = useState(true);
  const [excludeBrackets, setExcludeBrackets] = useState(true);
  const [noRepeated, setNoRepeated] = useState(false);

  const lowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';
  const symbols = "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~";
  const ambiguous = 'il1Lo0O';
  const brackets = '(){}[]<>';

  const generatePassword = () => {
    let characters = '';
    if (includeLower) characters += lowerCase;
    if (includeUpper) characters += upperCase;
    if (includeNumbers) characters += numbers;
    if (includeSymbols) characters += symbols;

    if (excludeAmbiguous) characters = characters.replace(new RegExp(`[${ambiguous}]`, 'g'), '');
    if (excludeBrackets) characters = characters.replace(new RegExp(`[${brackets}]`, 'g'), '');

    let newPassword = '';
    while (newPassword.length < length) {
      const char = characters.charAt(Math.floor(Math.random() * characters.length));
      if (noRepeated && newPassword.includes(char)) continue;
      newPassword += char;
    }

    setPassword(newPassword);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(password).then(() => {
      alert('Password copied to clipboard');
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };

  return (
    <div className="password-generator">
      <h1>Random Password Generator</h1>
      <div className="password-display">
        <input type="text" value={password} readOnly />
        <button onClick={generatePassword}>Regenerate</button>
      </div>
      <div className="password-strength">
        <label>Password Strength: Strong</label>
        <div className="password-entropy">
          <label>Password Entropy: 60.9 bits</label>
        </div>
      </div>
      <button onClick={copyToClipboard}>Copy Password</button>
      <div className="password-options">
        <label>
          Password Length:
          <input
            type="number"
            value={length}
            onChange={(e) => setLength(parseInt(e.target.value, 10))}
            min="1"
            max="128"
          />
          <input
            type="range"
            value={length}
            onChange={(e) => setLength(parseInt(e.target.value, 10))}
            min="1"
            max="128"
          />
        </label>
        <label>
          <input
            type="checkbox"
            checked={includeLower}
            onChange={(e) => setIncludeLower(e.target.checked)}
          />
          Include Lower Case (a-z)
        </label>
        <label>
          <input
            type="checkbox"
            checked={includeUpper}
            onChange={(e) => setIncludeUpper(e.target.checked)}
          />
          Include Upper Case (A-Z)
        </label>
        <label>
          <input
            type="checkbox"
            checked={includeNumbers}
            onChange={(e) => setIncludeNumbers(e.target.checked)}
          />
          Include Numbers (0-9)
        </label>
        <label>
          <input
            type="checkbox"
            checked={includeSymbols}
            onChange={(e) => setIncludeSymbols(e.target.checked)}
          />
          Include Symbols (!&quot;#$%&amp;'()*+,-./:;&lt;=&gt;?@[\\]^_`&#123;|&#125;~)
        </label>
        <label>
          <input
            type="checkbox"
            checked={excludeAmbiguous}
            onChange={(e) => setExcludeAmbiguous(e.target.checked)}
          />
          Exclude Ambiguous Characters (il1Lo0O)
        </label>
        <label>
          <input
            type="checkbox"
            checked={excludeBrackets}
            onChange={(e) => setExcludeBrackets(e.target.checked)}
          />
          Exclude Brackets (&lt;&gt;[]&#123;&#125;())
        </label>
        <label>
          <input
            type="checkbox"
            checked={noRepeated}
            onChange={(e) => setNoRepeated(e.target.checked)}
          />
          No Repeated Characters
        </label>
        <button onClick={generatePassword}>Generate</button>
      </div>
    </div>
  );
};

export default PasswordGenerator;
