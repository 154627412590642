import React, { useState, useEffect } from 'react';
import './GdprPopup.css';

const GdprPopup = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('gdprConsent');
    if (!consent) {
      setShowPopup(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('gdprConsent', 'true');
    setShowPopup(false);
  };

  return (
    showPopup && (
      <div className="gdpr-popup">
        <div className="gdpr-popup-content">
          <h2>Privacy Notice</h2>
          <p>We use cookies to improve your experience on our site and to show you personalized content. By continuing to use this site, you consent to our use of cookies.</p>
          <button onClick={handleAccept}>Accept</button>
        </div>
      </div>
    )
  );
};

export default GdprPopup;
