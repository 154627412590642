import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import TextCaseConverter from './TextCaseConverter';
import BigTextGenerator from './BigTextGenerator';
import PasswordGenerator from './PasswordGenerator';
import GdprPopup from './GdprPopup';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <GdprPopup />
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/big-text-generator">Big Text Generator</Link>
            </li>
            <li>
              <Link to="/password-generator">Password Generator</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<TextCaseConverter />} />
          <Route path="/big-text-generator" element={<BigTextGenerator />} />
	      <Route path="/password-generator" element={<PasswordGenerator />} />
        </Routes>
	    <Analytics />
      </div>
    </Router>
  );
}

export default App;
