const languages = {
  en: {
    sentenceCase: "Sentence case",
    lowerCase: "lower case",
    upperCase: "UPPER CASE",
    capitalizedCase: "Capitalized Case",
    alternatingCase: "aLtErNaTiNg cAsE",
    titleCase: "Title Case",
    inverseCase: "InVeRsE CaSe",
    downloadText: "Download Text",
    copyToClipboard: "Copy to Clipboard",
    clear: "Clear",
  },
  es: {
    sentenceCase: "Caso de oración",
    lowerCase: "minúsculas",
    upperCase: "MAYÚSCULAS",
    capitalizedCase: "Caso Capitalizado",
    alternatingCase: "cAsO aLtErNaDo",
    titleCase: "Caso de Título",
    inverseCase: "Caso Inverso",
    downloadText: "Descargar texto",
    copyToClipboard: "Copiar al portapapeles",
    clear: "Limpiar",
  },
  de: {
    sentenceCase: "Satzfall",
    lowerCase: "kleinbuchstaben",
    upperCase: "GROSSBUCHSTABEN",
    capitalizedCase: "Großgeschriebener Fall",
    alternatingCase: "aLtErNiErEnDeS GeScHiEd",
    titleCase: "Titel Fall",
    inverseCase: "Umgekehrter Fall",
    downloadText: "Text herunterladen",
    copyToClipboard: "In die Zwischenablage kopieren",
    clear: "Löschen",
  },
  zh: {
    sentenceCase: "句子大小写",
    lowerCase: "小写",
    upperCase: "大写",
    capitalizedCase: "大写字母",
    alternatingCase: "交替大小写",
    titleCase: "标题大小写",
    inverseCase: "反转大小写",
    downloadText: "下载文本",
    copyToClipboard: "复制到剪贴板",
    clear: "清除",
  },
  ja: {
    sentenceCase: "文の大文字小文字",
    lowerCase: "小文字",
    upperCase: "大文字",
    capitalizedCase: "資本化されたケース",
    alternatingCase: "交互のケース",
    titleCase: "タイトルケース",
    inverseCase: "逆のケース",
    downloadText: "テキストをダウンロード",
    copyToClipboard: "クリップボードにコピー",
    clear: "クリア",
  },
  fr: {
    sentenceCase: "Casse de phrase",
    lowerCase: "minuscules",
    upperCase: "MAJUSCULES",
    capitalizedCase: "Casse capitalisée",
    alternatingCase: "CaSsE AlTeRnÉe",
    titleCase: "Casse du titre",
    inverseCase: "Casse inverse",
    downloadText: "Télécharger le texte",
    copyToClipboard: "Copier dans le presse-papiers",
    clear: "Effacer",
  },
  pt: {
    sentenceCase: "Caso de sentença",
    lowerCase: "letras minúsculas",
    upperCase: "LETRAS MAIÚSCULAS",
    capitalizedCase: "Caso Capitalizado",
    alternatingCase: "CaSo AlTeRnAdO",
    titleCase: "Caso de Título",
    inverseCase: "Caso Inverso",
    downloadText: "Baixar texto",
    copyToClipboard: "Copiar para área de transferência",
    clear: "Limpar",
  },
  ru: {
    sentenceCase: "Прописные буквы",
    lowerCase: "нижний регистр",
    upperCase: "ВЕРХНИЙ РЕГИСТР",
    capitalizedCase: "Заглавные буквы",
    alternatingCase: "пЕрЕмЕнНаЯ реГиСтРаЦиЯ",
    titleCase: "Заглавные буквы",
    inverseCase: "Обратный регистр",
    downloadText: "Скачать текст",
    copyToClipboard: "Копировать в буфер обмена",
    clear: "Очистить",
  },
  it: {
    sentenceCase: "Maiuscolo e minuscolo",
    lowerCase: "lettere minuscole",
    upperCase: "LETTERE MAIUSCOLE",
    capitalizedCase: "Maiuscole",
    alternatingCase: "cAsE AlTeRnAtE",
    titleCase: "Titolo Case",
    inverseCase: "Caso inverso",
    downloadText: "Scarica testo",
    copyToClipboard: "Copia negli appunti",
    clear: "Pulisci",
  },
};

export default languages;
